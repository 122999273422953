<template>
  <form action="">
    <div class="modal-card" style="width: auto; min-width: 250px">
      <header class="modal-card-head">
        <p class="modal-card-title">Export CSV</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Since">
          <b-datepicker
            v-model="fsince"
            class="is-active"
            :first-day-of-week="1"
            style="width: 130px"
            placeholder="Click to select"
            append-to-body
          >
            <button class="button is-primary" @click="fsince = new Date()">
              <b-icon icon="calendar-today"></b-icon>
              <span>Today</span>
            </button>

            <button class="button is-danger" @click="fsince = null">
              <b-icon icon="close"></b-icon>
              <span>Clear</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field label="Before">
          <b-datepicker
            v-model="fbefore"
            class="is-active"
            :first-day-of-week="1"
            style="width: 130px"
            placeholder="Click to select"
            append-to-body
          >
            <button class="button is-primary" @click="fbefore = new Date()">
              <b-icon icon="calendar-today"></b-icon>
              <span>Today</span>
            </button>

            <button class="button is-danger" @click="fbefore = null">
              <b-icon icon="close"></b-icon>
              <span>Clear</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-checkbox v-model="flattening">Flatten JSON</b-checkbox>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Close" @click="$emit('close')" />
        <b-button label="Download" @click="download" type="is-success" />
      </footer>
    </div>
  </form>
</template>

<script>

export default {
  name: 'ExportDialog',
  props: ['since', 'before', 'deviceId', 'groupId', 'sortField', 'sortOrder'],
  data() {
    return {
      fsince: this.$props.since,
      fbefore: this.$props.before,
      flattening: false,
    };
  },
  methods: {
    download() {
      const url = [`${this.baseApiUrl}/v1/messages/export-csv?token=${this.$auth.getToken()}`];

      if (this.deviceId) {
        url.push(`device_id=${encodeURIComponent(this.deviceId)}`);
      } else if (this.groupId) {
        url.push(`group_id=${encodeURIComponent(this.groupId)}`);
      } else {
        throw new Error('Ser deviceId or groupId');
      }

      if (this.sortField) url.push(`sort_field=${encodeURIComponent(this.sortField)}`);
      if (this.sortOrder) url.push(`sort_order=${encodeURIComponent(this.sortOrder)}`);
      if (this.flattening) url.push(`flattening=${encodeURIComponent('.')}`);

      if (this.fsince) {
        const since = +new Date(this.fsince);
        url.push(`since=${encodeURIComponent(since)}`);
      }

      if (this.fbefore) {
        const before = +new Date(this.fbefore)
          + 23 * 60 * 60 * 1000
          + 59 * 60 * 1000
          + 59 * 1000;
        url.push(`before=${encodeURIComponent(before)}`);
      }

      window.open(url.join('&'), '_blank');

      this.$emit('close');
    },
  },
};
</script>
