<template>
  <div class="table-container messages">
    <b-table
      :data="messages"
      :loading="loading"
      paginated
      backend-pagination
      pagination-position="both"
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      :current-page="page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :checkable="isAdmin"
      checkbox-position="left"
      :checked-rows.sync="checkedRows"
      detailed
      detail-key="id"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      :row-class="(row, index) => row.type === 'downlink' && 'downlink'"
    >
      <b-table-column field="index" label="Index" v-slot="props">
        {{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}
      </b-table-column>

      <b-table-column field="id" label="ID" sortable v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="created_at" label="Created at" sortable v-slot="props">
        {{ props.row.created_at | moment('YYYY-MM-DD HH:mm:ss') }}
      </b-table-column>

      <b-table-column field="" label="Info" v-slot="props">
        <b-icon
          title="battery low"
          icon="battery-10"
          v-if="props.row.data && props.row.data.battery && props.row.data.battery.low"
        >
        </b-icon>
        <signal :message="props.row"></signal>
        <b-icon
          title="Pull message confirmed"
          :icon="props.row.confirmed ? 'checkbox-outline' : 'checkbox-blank-outline'"
        >
        </b-icon>
        <b-icon
          :title="props.row.type === 'downlink' ? 'Message type downlink' : 'Message type uplink'"
          :icon="props.row.type === 'downlink' ? 'arrow-down-bold-outline' : 'arrow-up-bold-outline'"
        >
        </b-icon>
      </b-table-column>

      <b-table-column field="actions" label="Actions" v-slot="props">
        <b-button size="is-small" v-on:click="doCopy(props.row)" title="Copy to clipboard" class="mr-1">
          <b-icon icon="clipboard" size="is-small" />
        </b-button>
        <router-link :to="{ name: 'message', params: { id: props.row.id } }" title="Detail" class="mr-1">
          <b-button size="is-small">
            <b-icon icon="eye" size="is-small" />
          </b-button>
        </router-link>
        <a v-if="isAdmin" v-on:click="showDeleteDialog(props.row)" title="Delete">
          <b-button size="is-small">
            <b-icon icon="trash-can" size="is-small" />
          </b-button>
        </a>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <div class="field">
          <label class="label">Data</label>
          <vue-json-pretty :data="props.row.data"></vue-json-pretty>
          <b-button size="is-small" class="bd-copy" v-on:click="doCopy(props.row.data)"> copy </b-button>
        </div>
        <div class="field" v-if="props.row.raw">
          <label class="label">Raw</label>
          <div class="control raw-msg">
            {{ props.row.raw }}
          </div>
        </div>

        <CallbackDetail :data="props.row.callback"></CallbackDetail>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nothing here.</p>
          </div>
        </section>
      </template>

      <template slot="bottom-left"> </template>

      <template slot="top-left">
        <b-field horizontal label="Since" class="mb-0" custom-class="mr-1 is-align-items-baseline">
          <b-datepicker
            v-model="since"
            class="is-active"
            :first-day-of-week="1"
            style="width: 130px"
            placeholder="Click to select"
          >
            <button class="button is-primary" @click="since = new Date()">
              <b-icon icon="calendar-today"></b-icon>
              <span>Today</span>
            </button>

            <button class="button is-danger" @click="since = null">
              <b-icon icon="close"></b-icon>
              <span>Clear</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field horizontal label="Before" class="mb-0 mr-3 ml-3" custom-class="mr-1">
          <b-datepicker
            v-model="before"
            class="is-active"
            :first-day-of-week="1"
            style="width: 130px"
            placeholder="Click to select"
          >
            <button class="button is-primary" @click="before = new Date()">
              <b-icon icon="calendar-today"></b-icon>
              <span>Today</span>
            </button>

            <button class="button is-danger" @click="before = null">
              <b-icon icon="close"></b-icon>
              <span>Clear</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined :loading="loading">REFRESH</b-button>

        <b-button v-on:click="csvExport" type="is-info" icon-left="download" outlined class="ml-3">EXPORT</b-button>

        <b-button
          v-if="isAdmin"
          :disabled="!checkedRows.length"
          v-on:click="showDeleteCheckedRowDialog"
          type="is-danger"
          icon-left="trash-can"
          class="ml-3"
          >DELETE</b-button
        >
      </template>
    </b-table>

    <div class="modal is-active" v-if="itemForDelete">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete message {{ itemForDelete.name }}?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteItem">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="deleteCheckedRowDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteCheckedRowDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete all checked messages?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteCheckedRows">Delete</button>
          <button class="button" v-on:click="closeDeleteCheckedRowDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <b-loading is-full-page :active.sync="loadingFullPage"></b-loading>
  </div>
</template>

<script>
import { clone } from 'lodash';
import VueJsonPretty from 'vue-json-pretty';
import Message from '../models/Message';
import CallbackDetail from '../components/CallbackDetail.vue';
import ExportDialog from '../components/ExportDialog.vue';
import Signal from '../components/Signal.vue';

export default {
  name: 'messages',
  components: {
    VueJsonPretty,
    CallbackDetail,
    Signal,
  },
  data() {
    return {
      messages: [],
      total: 0,
      perPage: 20,
      sortField: 'created_at',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      itemForDelete: null,
      timer: null,
      loading: false,
      offset: 0,
      checkedRows: [],
      deleteCheckedRowDialog: false,
      loadingFullPage: false,
      since: null,
      before: null,
    };
  },
  created() {
    this.fetch();
    // this.timer = setInterval(this.fetch, 5000);
  },
  watch: {
    since() {
      this.fetch();
    },
    before() {
      this.fetch();
    },
    page() {
      this.fetch();
    },
  },
  methods: {
    createParams() {
      let since = null;
      if (this.since) {
        since = +new Date(this.since);
      }
      let before = null;
      if (this.before) {
        before = +new Date(this.before) + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000;
      }
      return {
        device_id: this.$route.params.deviceId,
        sort_field: this.sortField,
        sort_order: this.sortOrder,
        since,
        before,
      };
    },
    async fetch() {
      this.loading = true;
      this.messages = [];
      this.checkedRows = [];
      this.offset = (this.page - 1) * this.perPage;

      const params = this.createParams();
      params.limit = this.perPage;
      params.offset = this.offset;

      const res = await this.$http.get('/v1/messages', { params });
      this.messages = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    onPageChange(newPage) {
      // this.page = page;
      this.$router.replace({ query: { page: newPage } });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    async deleteItem() {
      await Message.delete(this.itemForDelete.id);
      this.fetch();
      this.closeDeleteDialog();
      this.$toast.success('Message was deleted');
    },
    showDeleteDialog(item) {
      this.itemForDelete = item;
    },
    closeDeleteDialog() {
      this.itemForDelete = null;
    },
    doCopy(message) {
      const toast = this.$toast;
      const msg = clone(message);
      if (msg.callback) {
        delete msg.callback;
      }
      this.$copyText(JSON.stringify(msg, null, 2)).then(
        () => {
          toast.success('Copied to clipboard');
        },
        () => {
          toast.error('Can not copy');
        }
      );
    },
    showDeleteCheckedRowDialog() {
      this.deleteCheckedRowDialog = this.checkedRows.length > 0;
    },
    closeDeleteCheckedRowDialog() {
      this.deleteCheckedRowDialog = false;
    },
    async deleteCheckedRows() {
      this.closeDeleteCheckedRowDialog();
      this.loadingFullPage = true;
      await Promise.all(this.checkedRows.map((item) => Message.delete(item.id)));
      this.loadingFullPage = false;
      this.fetch();
      this.$toast.success('All message was deleted');
    },
    csvExport() {
      const props = {
        since: this.since,
        before: this.before,
        deviceId: this.$route.params.deviceId,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      };
      this.$buefy.modal.open({
        parent: this,
        component: ExportDialog,
        props,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
      });
    },
  },
  computed: {
    page() {
      return parseInt(this.$route.query.page, 10) || 1;
    },
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
};
</script>

<style>
.bd-copy {
  background: 0 0;
  border: none;
  cursor: pointer;
  font-size: 0.625rem;
  outline: 0;
  padding-bottom: 0;
  padding-top: 0;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}
.bd-copy:hover {
  background-color: #ffdd57;
  color: rgba(0, 0, 0, 0.7);
}
.bd-copy:focus {
  box-shadow: none !important;
}
.field {
  position: relative;
}
.nowrap {
  display: inline-block;
  white-space: nowrap;
}
.downlink {
  background-color: aliceblue;
}
.table-container.messages .field-label.is-normal {
  padding-top: 0.375em !important;
}
</style>
