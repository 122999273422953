<template>
  <div v-if="message">
    <label>{{ message.created_at }} sequence :{{message.data.frame.sequence}}</label>

    <div v-for="shelf in message.data.sensor.external.shelf" v-bind:key="shelf.address">
      <b>{{ shelf.address }}</b>

      <table class="table is-bordered">
        <tbody>
          <tr v-for="row in 5" v-bind:key="row">
            <td
              v-for="i in 9"
              v-bind:key="i"
              v-bind:class="{ active: shelf.inputs[ (row - 1) + (9 - i) * 5 ] }"
              :title="(row - 1) + (9 - i) * 5"
            >{{ shelf.inputs[ (row - 1) + (9 - i) * 5 ] }}</td>
          </tr>
        </tbody>
        <tbody></tbody>
      </table>
    </div>
    <div class="is-divider" data-content="Message"></div>
    <vue-json-pretty :data="message"></vue-json-pretty>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import Message from '../models/Message';

export default {
  name: 'messages',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      message: null,
      loading: false,
      timer: null,
      total: null,
    };
  },
  created() {
    this.fetch();
    this.timer = setInterval(this.fetch, 5000);
  },
  methods: {
    async fetch() {
      this.loading = true;
      const res = await Message.getAll(
        this.$route.params.deviceId,
        'created_at',
        'desc',
        1,
        0,
        null,
        null
      );
      this.message = res.data && res.data.length === 1 ? res.data[0] : null;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.active {
  background-color: red;
}
</style>
